.theme-light *,
.theme-dark * {
  transition: background-color 0s !important;
}

body .theme-dark {
  color: #e1e1e1;
}

.theme-dark .color-important {
  color: #fff !important;
}

.theme-light .ant-layout-header {
  background: #00c3b6;
}

.theme-light .app-sub-header {
  background: #fff;
  border-top: solid 1px #f0f0f0;
}
.theme-light .ant-menu-horizontal>.ant-menu-submenu .ant-menu-submenu-title
{
	color: rgba(255,255,255,1);
}
.theme-light .ant-menu-horizontal>.ant-menu-submenu .ant-menu-submenu-title:hover, .theme-light .ant-menu-horizontal>.ant-menu-submenu .ant-menu-submenu-title
{
	color: rgba(255,255,255,0.75);
}
.theme-dark .app-sub-header {
  color: #e1e1e1;
  background: #1e1e1e;
  border-color: #1e1e1e;
}

.theme-dark .ant-typography {
  color: #e1e1e1;
}

.theme-dark .ant-dropdown-menu,
.theme-dark .ant-menu-submenu-popup {
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48),
    0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48),
    0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  -webkit-transform: translate3d(0, 0, 0);
}

.ant-menu-light {
  background: transparent;
}

.theme-dark .ant-layout-header {
  background: #121212;
}

.theme-dark .ant-menu {
  color: #e1e1e1;
  background: #121212;
}

.theme-dark .ant-menu.ant-menu-sub {
  /* color: #e1e1e1; */
  background: #1e1e1e !important;
}

.theme-dark .ant-input {
  background-color: transparent !important;
}

.theme-dark .ant-input-suffix svg {
  color: #e1e1e1;
}

.theme-dark .ant-input-search {
  background-color: #1e1e1e;
  border-color: #1e1e1e;
}

.theme-dark .ant-dropdown-menu,
.theme-dark.ant-select-dropdown {
  background-color: #1f1f1f;
}

.theme-dark .ant-dropdown-menu-item,
.theme-dark .ant-select-item {
  color: #e1e1e1;
}

.theme-dark .ant-dropdown-menu-item:hover,
.theme-dark .ant-select-item:hover,
.theme-dark .ant-dropdown-menu-submenu-title:hover,
.theme-dark .ant-select-submenu-title:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.theme-dark
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: rgba(255, 255, 255, 0.08);
}
.theme-dark
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(255, 255, 255, 0.65);
  font-weight: 600;
  background-color: #111b26;
}
.theme-dark
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: #177ddc;
}
.theme-dark .ant-select-item-option-disabled {
  color: rgba(255, 255, 255, 0.3);
  cursor: not-allowed;
}

.theme-dark .ant-input {
  color: #e1e1e1;
}

.theme-dark.ant-layout {
  background-color: #121212;
}

.theme-dark .ant-card {
  background: #1e1e1e;
}

.theme-dark .ant-card-head {
  color: #e1e1e1;
  border-color: #1e1e1e;
}

.theme-dark .ant-card-extra {
  color: #e1e1e1;
}

.theme-dark .ant-card.ant-card-bordered {
  border-color: #1e1e1e;
}

.theme-dark .ant-statistic-title {
  color: #a5a5a5;
}

.theme-dark .ant-statistic-content {
  color: #e1e1e1;
}

.theme-dark .color-normal {
  color: #e1e1e1 !important;
}

.theme-dark .ant-layout-footer {
  background: #121212;
  color: #e1e1e1;
}

.theme-dark .ant-timeline-item-tail {
  border-color: gray !important;
}

.theme-dark .ant-card-body,
.theme-dark .ant-slider-mark-text {
  color: #e1e1e1 !important;
}

.theme-dark .ant-skeleton.ant-skeleton-active {
  opacity: 0.25;
}

.theme-dark .ant-table thead > tr > th {
  color: #e1e1e1;
  background: #1e1e1e !important;
  border-color: #1e1e1e;
}

.theme-dark .ant-table .ant-table-cell {
  color: #e1e1e1;
  background: #1e1e1e !important;
  border-color: #1e1e1e;
}

/* Wait until className works on popover and dropdown */
.theme-dark .ant-popover-inner {
  background-color: #1f1f1f;
}

.theme-dark
  .ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: #1e1e1e !important;
}

.theme-dark .ant-table-footer {
  background: #1e1e1e !important;
}

.theme-dark .ant-pagination-item-active {
  background-color: transparent;
}

.theme-dark .ant-pagination-prev a,
.theme-dark .ant-pagination-next a,
.theme-dark .ant-pagination-item a,
.theme-dark
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
.theme-dark
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
.theme-dark .ant-pagination-item-link {
  color: #e1e1e1;
}

.theme-dark .ant-pagination-item-active a {
  color: #1890ff;
}

.theme-dark .ant-pagination-disabled .ant-pagination-item-link,
.theme-dark .ant-pagination-disabled a {
  color: gray !important;
}

.theme-dark .ant-empty-description {
  color: gray;
}

.theme-dark .ant-empty-img-default-ellipse {
  fill-opacity: 0.08;
  fill: #fff;
}
.theme-dark .ant-empty-img-default-path-1 {
  fill: #262626;
}
.theme-dark .ant-empty-img-default-path-2 {
  fill: url(#linearGradient-1);
}
.theme-dark .ant-empty-img-default-path-3 {
  fill: #595959;
}
.theme-dark .ant-empty-img-default-path-4 {
  fill: #434343;
}
.theme-dark .ant-empty-img-default-path-5 {
  fill: #595959;
}
.theme-dark .ant-empty-img-default-g {
  fill: #434343;
}
.theme-dark .ant-empty-img-simple-ellipse {
  fill: #fff;
  fill-opacity: 0.08;
}
.theme-dark .ant-empty-img-simple-g {
  stroke: #434343;
}
.theme-dark .ant-empty-img-simple-path {
  fill: #262626;
  stroke: #434343;
}

.theme-dark .transaction-card .ant-row:not(:last-child) {
  border-color: #2f2f2f;
}

.theme-dark .detail-layout .ant-row .ant-col .default-color {
  color: #fff;
}

.theme-dark .ant-tag.tag-send,
.theme-dark .ant-tag.tag-offline {
  color: #fff;
  border-color: #e04576;
  background-color: #e04576 !important;
}

.theme-dark .bottom-gradient {
  background: linear-gradient(transparent, #1e1e1e);
}

.theme-dark .detail-layout .ant-row + .ant-row {
  border-color: #2f2f2f;
}

.theme-dark .detail-layout .divider {
  border-color: #2f2f2f;
}

.theme-dark .ant-btn.ant-btn-background-ghost {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.7);
}

.theme-dark .ant-btn.ant-btn-background-ghost:hover,
.theme-dark .ant-btn.ant-btn-background-ghost:focus,
.theme-dark .ant-btn.ant-btn-background-ghost:active,
.theme-dark .ant-btn.ant-btn-background-ghost.active {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.5);
}
